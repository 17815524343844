import Logo from '../Assets/logo.svg';

export default function Construction() {

    return (
        <div>
          <img src={Logo} alt='' style={{'width': '50%'}}/>
          <p style={{'fontSize': '24px'}}>under construction</p>
        </div>
      );
}